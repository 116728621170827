var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"updatePassword"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Old Password","label-for":"old_password"}},[_c('validation-provider',{attrs:{"name":"Old Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0
                    ? 'is-invalid'
                    : null},[_c('b-form-input',{attrs:{"id":"old_password","name":"old_password","state":errors.length > 0
                      ? false
                      : null,"type":_vm.passwordFieldTypeOld,"placeholder":"Old Password"},model:{value:(_vm.passwordValueOld),callback:function ($$v) {_vm.passwordValueOld=$$v},expression:"passwordValueOld"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconOld},on:{"click":_vm.togglePasswordOld}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"New Password","label-for":"new_password"}},[_c('validation-provider',{attrs:{"name":"New Password","rules":"required|min:8","vid":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0
                    ? 'is-invalid'
                    : null},[_c('b-form-input',{attrs:{"id":"new_password","name":"new_password","state":errors.length > 0
                      ? false
                      : null,"type":_vm.passwordFieldTypeNew,"placeholder":"New Password"},model:{value:(_vm.newPasswordValue),callback:function ($$v) {_vm.newPasswordValue=$$v},expression:"newPasswordValue"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"New Password","label-for":"retype_new_password"}},[_c('validation-provider',{attrs:{"name":"Password Confirmation","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0
                    ? 'is-invalid'
                    : null},[_c('b-form-input',{attrs:{"id":"retype_new_password","name":"retype_new_password","state":errors.length > 0
                      ? false
                      : null,"type":_vm.passwordFieldTypeRetype,"placeholder":"Retype New Password"},model:{value:(_vm.RetypePassword),callback:function ($$v) {_vm.RetypePassword=$$v},expression:"RetypePassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
              'rgba(255, 255, 255, 0.15)'
            ),expression:"\n              'rgba(255, 255, 255, 0.15)'\n            ",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.saveChanges.apply(null, arguments)}}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),(!_vm.loading)?_c('span',[_vm._v(" Save changes")]):_vm._e(),(_vm.loading)?_c('span',[_vm._v(" Saving changes")]):_vm._e()],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
              'rgba(186, 191, 199, 0.15)'
            ),expression:"\n              'rgba(186, 191, 199, 0.15)'\n            ",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }