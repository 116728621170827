<template>
  <b-card title="Document Storage">
    <b-card-text>Upload and store your documents here and use them for future
      applications.</b-card-text>
    <b-row>
      <b-col
        lg="8"
        sm="4"
      >
        <b-alert
          variant="warning"
          show
        >
          <h4 class="alert-heading">
            Note
          </h4>
          <div class="alert-body">
            Certificates you uploaded in the education tab cannot be deleted
            here. The description will help you identify documents within the
            system.
          </div>
        </b-alert>
      </b-col>
      <b-col
        lg="4"
        sm="4"
      >
        <statistic-card-horizontal
          icon="ServerIcon"
          color="success"
          :statistic="
            `${(stats.totalSize / 1000000).toFixed(2).toLocaleString()} MB`
          "
          statistic-title="Storage Usage"
        />
      </b-col>
    </b-row>

    <b-card-body class="invoice-padding form-item-section">
      <div
        ref="form"
        class="repeater-form"
        :style="{ height: trHeight }"
      >
        <b-row
          v-for="(item, index) in documents.list"
          :key="index"
          ref="row"
          class="pb-2"
        >
          <b-col cols="12">
            <div class="d-none d-lg-flex">
              <div class="form-item-action-col" />
            </div>
            <div class="d-flex border rounded">
              <b-row class="flex-grow-1 p-2">
                <b-col
                  cols="12"
                  lg="12"
                >
                  <validation-observer :ref="`updateDocuments-${index}`">
                    <b-form>
                      <label class="d-inline d-lg-none">Description</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Description"
                        rules="required"
                      >
                        <b-input-group>
                          <b-form-input
                            :id="`description-${index}`"
                            v-model="item.description"
                            placeholder="Description"
                            :state="errors.length > 0 ? false : null"
                          />
                          <b-input-group-append v-if="item.status.cloud">
                            <b-button
                              :variant="
                                `outline-${
                                  item.status.loading ? 'warning' : 'success'
                                }`
                              "
                              :disabled="item.status.loading"
                              @click.prevent="updateDocumentDescription(index)"
                            >
                              <b-spinner
                                v-if="item.status.loading"
                                small
                              />
                              <feather-icon
                                v-if="!item.status.loading"
                                icon="CheckIcon"
                                class="mr-50"
                              />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger mb-2">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form>
                  </validation-observer>
                </b-col>

                <b-col
                  v-if="!item.status.cloud"
                  cols="12"
                  lg="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Document Upload"
                    rules="required"
                  >
                    <b-form-file
                      :id="`document_${index}`"
                      v-model="item.file"
                      accept=".jpg, .png, .gif, .pdf, .doc, .docx, .txt"
                      :state="
                        errors.length > 0 ? false : null || Boolean(item.file)
                      "
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      @input="validateFile(index)"
                    >
                      <template
                        slot="file-name"
                        slot-scope="{ names }"
                      >
                        <b-badge variant="primary">
                          {{ names[0] }}
                        </b-badge>
                        <b-badge
                          v-if="names.length > 1"
                          variant="dark"
                          class="ml-1"
                        >
                          +
                          {{ names.length - 1 }}
                          More files
                        </b-badge>
                      </template>
                    </b-form-file>
                    <small class="text-danger mb-1">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="item.status.cloud"
                  cols="12"
                  lg="12"
                >
                  <small>{{
                           `${(item.size / 1000000).toFixed(2).toLocaleString()} MB`
                         }}
                    <strong>·</strong>
                    {{
                      formatDate(item.updatedAt, {
                        month: 'short',
                        day: 'numeric',
                        weekday: 'short',
                      })
                    }}
                    <strong>·</strong>
                    {{ item.endpoint ? item.endpoint : 'Education' }}/{{
                      item.name
                    }}</small>
                  <b-row class="mt-1">
                    <b-col>
                      <b-button
                        v-if="false"
                        variant="outline-success"
                        pill
                        class="mr-3"
                      >
                        <feather-icon
                          icon="DownloadIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Download</span>
                      </b-button>
                      <b-button
                        v-if="!item.adminRequired"
                        variant="outline-danger"
                        pill
                        @click.prevent="deleteDocument(index)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          class="mr-50"
                        />
                        <span class="align-middle">Delete</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-else
                  class="mt-2"
                  cols="12"
                  lg="12"
                >
                  <b-card-text>{{
                    item.description && item.file
                      ? `File ready to be uploaded. Click upload button below to
                        upload file to the cloud.`
                      : `Enter a description and choose a file to upload to the cloud.`
                  }}</b-card-text>
                  <b-row>
                    <b-col>
                      <b-button
                        variant="outline-secondary"
                        pill
                        :disabled="
                          item.description === null ||
                            item.file === null ||
                            item.status.loading
                        "
                        @click="uploadDocument(index)"
                      >
                        <b-spinner
                          v-if="item.status.loading"
                          small
                          type="grow"
                          class="mr-50"
                        />
                        <feather-icon
                          v-else
                          icon="UploadIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Upload</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <div
                v-if="!item.status.cloud"
                class="
                  d-flex
                  flex-column
                  justify-content-between
                  border-left
                  py-50
                  px-25
                "
              >
                <feather-icon
                  size="16"
                  icon="XIcon"
                  class="cursor-pointer"
                  @click="removeItem(index)"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="primary"
        :disabled="loading || loadingFirst"
        @click="addNewItemInItemForm"
      >
        <b-spinner
          v-if="loading || loadingFirst"
          small
          type="grow"
        />
        Add Item
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable space-before-function-paren */
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import {
  BButton,
  BForm,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BSpinner,
  BFormFile,
  BAlert,
  BBadge,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line object-curly-newline
import { required, email, integer, length, min } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BSpinner,
    BFormFile,
    BAlert,
    BBadge,
    StatisticCardHorizontal,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadingFirst: true,
      loading: false,
      hasError: false,
      stats: {
        totalFiles: 0,
        totalSize: 0,
        capacity: null,
      },

      fileSizeLimit: 2 * 1000 * 1000,

      // validation
      required,
      email,
      integer,
      length,
      min,
    }
  },
  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'documents.available': function() {
      this.initTrHeight()
    },
    // eslint-disable-next-line func-names
    'documents.list': function() {
      this.initTrHeight()
    },
    // eslint-disable-next-line func-names
    '$refs.form.scrollHeight': function() {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    this.loadingFirst = true
    window.addEventListener('resize', this.initTrHeight)
    setTimeout(() => {
      this.stats.totalSize = 0
      this.$store
        .dispatch('auth/getUserDocuments')
        .then(() => {
          this.stats.totalFiles = this.$store.state.auth.userDocuments.length
          this.$store.state.auth.userDocuments.forEach(row => {
            // eslint-disable-next-line operator-linebreak
            this.stats.totalSize =
              parseInt(this.stats.totalSize, 10) + parseInt(row.size, 10)
            this.documents.list.push({
              id: row.id,
              description: row.description || row.name,
              name: row.name,
              size: row.size,
              type: row.type,
              last_modified: row.last_modified,
              url: row.url,
              encoding: row.encoding,
              endpoint: row.endpoint,
              adminRequired: row.adminRequired,
              createdAt: row.createdAt,
              updatedAt: row.updatedAt,
              status: {
                cloud: true,
                loading: false,
              },
            })
            this.initTrHeight()
          })
        })
        .catch(err => this.handleErrors(err))

      setTimeout(() => {
        this.loadingFirst = false
      }, 1500)
    }, 1000)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    calculateTotalSize() {
      let size = 0
      this.documents.list.forEach(doc => {
        if (doc.size) {
          size = parseInt(size, 10) + parseInt(doc.size, 10)
        }
      })
      this.stats.totalSize = size
      this.stats.totalFiles = this.documents.list.length
    },
    updateDocumentDescription(index) {
      const name = `updateDocuments-${index}`
      const fl = this.$refs[name][0]
      if (fl) {
        this.documents.list[index].status.loading = true
        fl.validate().then(async success => {
          if (success) {
            const { id, description } = this.documents.list[index]
            this.$store
              .dispatch('auth/updateUserDocumentDescription', {
                id,
                description,
              })
              .then(res => {
                if (res.status === 200) {
                  this.showToast(
                    'Success',
                    // eslint-disable-next-line operator-linebreak
                    'Document description successfully updated',
                    'Bell',
                    'success',
                    // eslint-disable-next-line comma-dangle
                    8000,
                  )
                } else {
                  this.showToast(
                    'Opps!',
                    // eslint-disable-next-line operator-linebreak
                    'Some error occurred while updating the document description',
                    'X',
                    'danger',
                    // eslint-disable-next-line comma-dangle
                    8000,
                  )
                }
              })
              .catch(err => this.handleErrors(err))
          } else {
            this.showToast(
              'Opps!',
              // eslint-disable-next-line operator-linebreak
              `Please correct errors in row number ${index +
                1} before submitting description.`,
              'X',
              'danger',
              // eslint-disable-next-line comma-dangle
              8000,
            )
          }
        })
        setTimeout(() => {
          this.documents.list[index].status.loading = false
        }, 2000)
      } else {
        this.showToast(
          'Opps!',
          'An error occurred. Please try again later.',
          'X',
          'danger',
          // eslint-disable-next-line comma-dangle
          8000,
        )
      }
    },
    deleteDocument(index) {
      this.documents.list[index].status.loading = true
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showToast(
            'Processing',
            // eslint-disable-next-line operator-linebreak
            'Your document is being deleted ...',
            'Clock',
            'warning',
            // eslint-disable-next-line comma-dangle
            8000,
          )
          const { id } = this.documents.list[index]
          this.$store
            .dispatch('auth/deleteUserDocument', {
              id,
            })
            .then(res => {
              if (res.status === 200) {
                this.removeItem(index)
                this.calculateTotalSize()
                this.showToast(
                  'Success',
                  // eslint-disable-next-line operator-linebreak
                  'Document successfully deleted',
                  'Bell',
                  'success',
                  // eslint-disable-next-line comma-dangle
                  8000,
                )
              } else {
                this.showToast(
                  'Opps!',
                  // eslint-disable-next-line operator-linebreak
                  'Some error occurred while updating the document description',
                  'X',
                  'danger',
                  // eslint-disable-next-line comma-dangle
                  8000,
                )
              }
            })
            .catch(err => this.handleErrors(err))
        }
      })
      this.documents.list[index].status.loading = false
    },
    uploadDocument(index) {
      const n = `updateDocuments-${index}`
      const fl = this.$refs[n][0]
      if (fl) {
        this.documents.list[index].status.loading = true
        fl.validate().then(async success => {
          if (success) {
            const { id, description, fileEncoded } = this.documents.list[index]
            if (!this.documents.list[index].fileMetaData) {
              this.showToast(
                'Warning!',
                // eslint-disable-next-line operator-linebreak
                'A valid document file is required',
                'X',
                'danger',
                // eslint-disable-next-line comma-dangle
                8000,
              )
            } else {
              // eslint-disable-next-line object-curly-newline
              const { size, lastModified, name, type } = this.documents.list[
                index
              ].fileMetaData
              this.$store
                .dispatch('auth/uploadUserDocument', {
                  id,
                  description,
                  file: fileEncoded,
                  size,
                  lastModified,
                  name,
                  type,
                  adminRequired: false,
                  encoding: 'base64',
                  endpoint: 'Documents',
                })
                .then(res => {
                  if (res.status === 200) {
                    const row = res.data.data
                    this.documents.list[index] = {
                      id: row.id,
                      description: row.description || row.name,
                      name: row.name,
                      size: row.size,
                      type: row.type,
                      last_modified: row.last_modified,
                      url: row.url,
                      encoding: row.encoding,
                      endpoint: row.endpoint,
                      adminRequired: row.adminRequired,
                      createdAt: row.createdAt,
                      updatedAt: row.updatedAt,
                      status: {
                        cloud: true,
                        loading: false,
                      },
                    }
                    this.calculateTotalSize()
                    this.showToast(
                      'Success',
                      // eslint-disable-next-line operator-linebreak
                      'Document successfully uploaded',
                      'Bell',
                      'success',
                      // eslint-disable-next-line comma-dangle
                      8000,
                    )
                  } else {
                    this.showToast(
                      'Opps!',
                      // eslint-disable-next-line operator-linebreak
                      'Some error occurred while updating the document description',
                      'X',
                      'danger',
                      // eslint-disable-next-line comma-dangle
                      8000,
                    )
                  }
                })
                .catch(err => this.handleErrors(err))
            }
          } else {
            this.showToast(
              'Opps!',
              // eslint-disable-next-line operator-linebreak
              `Please correct errors in row number ${index +
                1} before submitting description.`,
              'X',
              'danger',
              // eslint-disable-next-line comma-dangle
              8000,
            )
          }
        })
        setTimeout(() => {
          this.documents.list[index].status.loading = false
        }, 2000)
      } else {
        this.showToast(
          'Opps!',
          'An error occurred. Please try again later.',
          'X',
          'danger',
          // eslint-disable-next-line comma-dangle
          8000,
        )
      }
    },
    handleErrors(err) {
      this.loading = false
      if (err.response) {
        if ('data' in err.response) {
          if ('data' in err.response.data) {
            if (Array.isArray(err.response.data.data)) {
              err.response.data.data.forEach(param => {
                this.showToast(
                  `Error ${err.response.status}`,
                  param.message,
                  'X',
                  'danger',
                  // eslint-disable-next-line comma-dangle
                  15000,
                )
              })
            }
          } else {
            this.showToast(
              `Error ${err.response.status}`,
              `${err.response.data.message}`,
              'X',
              'danger',
              // eslint-disable-next-line comma-dangle
              15000,
            )
          }
        } else {
          this.showToast(
            `Error ${err.response.status}`,
            `${err.response.statusText}`,
            'X',
            'danger',
            // eslint-disable-next-line comma-dangle
            15000,
          )
          // eslint-disable-next-line function-paren-newline
        }
      } else {
        this.showToast(
          'Error',
          `${err.message}`,
          'X',
          'danger',
          // eslint-disable-next-line comma-dangle
          15000,
        )
        // eslint-disable-next-line function-paren-newline
      }
    },
    showToast(title, text, icon, variant, timeout = 10000) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.hasError = false
      this.$refs.form.style.overflow = 'hidden'
      this.documents.list.push(
        // eslint-disable-next-line comma-dangle
        JSON.parse(JSON.stringify(this.itemFormBlankItem)),
      )

      this.$nextTick(() => {
        // this.trAddHeight(this.$refs.row[0].offsetHeight)
        this.initTrHeight()
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      // check and remove items that require the removed index
      this.documents.list.splice(index, 1)
      this.initTrHeight()
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
    validateFile(i) {
      // eslint-disable-next-line operator-linebreak
      const index = i
      const { file } = this.documents.list[index]
      if (
        // eslint-disable-next-line operator-linebreak
        file !== null &&
        file.size > this.fileSizeLimit
      ) {
        this.showToast(
          'Invalid Upload',
          `Files should have a maximum size of 2MB. You selected a file which is ${
            // eslint-disable-next-line operator-linebreak
            (file.size / (1000 * 1000)).toFixed(2)
          } MB. This limit is to reduce transfer times when downloading or uploading a file.`,
          'X',
          'warning',
          // eslint-disable-next-line comma-dangle
          10000,
        )
        this.documents.list[index].file = null
      } else if (this.documents.list[index].file !== null) {
        // eslint-disable-next-line object-curly-newline
        const { size, lastModified, name, type } = this.documents.list[
          index
        ].file
        this.documents.list[index].fileMetaData = {
          size,
          lastModified,
          name,
          type,
        }
        this.fileToBase64(index)
      }
    },
    fileToBase64(index) {
      const reader = new FileReader()
      reader.readAsDataURL(this.documents.list[index].file)
      reader.onload = () => {
        const { result } = reader
        this.documents.list[index].fileEncoded = result
      }
      // eslint-disable-next-line func-names
      reader.onerror = function() {}
    },
  },
  setup() {
    const documents = ref({
      list: [],
      available: [],
    })

    const itemFormBlankItem = {
      id: null,
      description: null,
      name: null,
      size: null,
      type: null,
      last_modified: null,
      url: null,
      encoding: null,
      endpoint: null,
      adminRequired: null,
      status: {
        cloud: false,
        loading: false,
      },
    }

    return {
      documents,
      itemFormBlankItem,
      formatDate,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
