var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Work Experience"}},[_c('b-card-text',[_vm._v("Make sure to indicate the details correctly.")]),_c('validation-observer',{ref:"updateExperience"},[_c('b-form',[_c('b-card-body',{staticClass:"\n          invoice-padding\n          form-item-section\n        "},[_c('div',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight })},_vm._l((_vm.experience.list),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,staticClass:"pb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-none d-lg-flex"},[_c('b-row',{staticClass:"flex-grow-1 px-1"},[_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_vm._v(" Company ")]),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_vm._v(" Designation ")]),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_vm._v(" Start Date ")]),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_vm._v(" End Date ")])],1),_c('div',{staticClass:"form-item-action-col"})],1),_c('div',{staticClass:"d-flex border rounded"},[_c('b-row',{staticClass:"flex-grow-1 p-2"},[_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("Company")]),_c('validation-provider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":("company-" + index),"placeholder":"Company","state":errors.length > 0
                            ? false
                            : null},model:{value:(item.company),callback:function ($$v) {_vm.$set(item, "company", $$v)},expression:"item.company"}}),_c('small',{staticClass:"text-danger mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("Designation")]),_c('validation-provider',{attrs:{"name":"Designation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":("designation-" + index),"placeholder":"Designation","state":errors.length > 0
                            ? false
                            : null},model:{value:(
                          item.designation
                        ),callback:function ($$v) {_vm.$set(item, "designation", $$v)},expression:"\n                          item.designation\n                        "}}),_c('small',{staticClass:"text-danger mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("Start Date")]),_c('validation-provider',{attrs:{"name":"Start Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Start date","state":errors.length > 0
                            ? false
                            : null,"config":{
                          altInput: true,
                          altFormat: 'F j, Y',
                          dateFormat: 'Y-m-d',
                        }},model:{value:(
                          item.start_date
                        ),callback:function ($$v) {_vm.$set(item, "start_date", $$v)},expression:"\n                          item.start_date\n                        "}}),_c('small',{staticClass:"text-danger mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("End Date")]),_c('validation-provider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"End date","state":errors.length > 0
                            ? false
                            : null,"config":{
                          altInput: true,
                          altFormat: 'F j, Y',
                          dateFormat: 'Y-m-d',
                        },"disabled":!item.start_date},model:{value:(item.end_date),callback:function ($$v) {_vm.$set(item, "end_date", $$v)},expression:"item.end_date"}}),_c('small',{staticClass:"text-danger mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{staticClass:"\n                          d-inline d-lg-none\n                        "},[_vm._v("Description")]),_c('b-form-textarea',{staticClass:"mb-2 mb-lg-0",attrs:{"rows":"5","placeholder":"Describe this work experience i.e Duties, Salary etc"},model:{value:(
                          item.description
                        ),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"\n                          item.description\n                        "}}),_c('small',{staticClass:"text-danger mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"\n                    d-flex\n                    flex-column\n                    justify-content-between\n                    border-left\n                    py-50\n                    px-25\n                  "},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"size":"16","icon":"XIcon"},on:{"click":function($event){return _vm.removeItem(index)}}})],1)],1)])],1)}),1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
            'rgba(255, 255, 255, 0.15)'
          ),expression:"\n            'rgba(255, 255, 255, 0.15)'\n          ",modifiers:{"400":true}}],attrs:{"size":"sm","variant":"primary","disabled":_vm.loading || _vm.loadingFirst},on:{"click":_vm.addNewItemInItemForm}},[(_vm.loading || _vm.loadingFirst)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),_vm._v(" Add Item ")],1)],1),(_vm.experience.list.length > 0)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
          'rgba(255, 255, 255, 0.15)'
        ),expression:"\n          'rgba(255, 255, 255, 0.15)'\n        ",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary","disabled":_vm.loading || _vm.loadingFirst},on:{"click":function($event){$event.preventDefault();return _vm.saveChanges.apply(null, arguments)}}},[(_vm.loading || _vm.loadingFirst)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),(!_vm.loading)?_c('span',[_vm._v(" Save changes")]):_vm._e(),(_vm.loading)?_c('span',[_vm._v(" Saving changes")]):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }