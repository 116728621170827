<template>
  <b-card>
    <!-- form -->
    <validation-observer
      ref="updateInformationForm"
    >
      <b-form>
        <b-row>
          <b-col sm="6">
            <b-form-group
              label-for="countryList"
              label="Country"
            >
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <v-select
                  id="countryList"
                  v-model="country"
                  :dir="
                    $store.state.appConfig.isRTL
                      ? 'rtl'
                      : 'ltr'
                  "
                  label="countryName"
                  :options="countryOption"
                  :selectable="
                    option =>
                      option.countryName.includes(
                        'Kenya',
                      )
                  "
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="National/Passport ID"
              label-for="nationalID"
            >
              <validation-provider
                #default="{ errors }"
                name="National/Passport ID"
                rules="required|integer|min:8|regex:^([0-9]+)$"
              >
                <b-form-input
                  id="nationalID"
                  v-model="nationalID"
                  name="nationalID"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  placeholder="National/Passport ID"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label-for="yoeList"
              label="Years of Experience"
            >
              <validation-provider
                #default="{ errors }"
                name="Years of Experience"
                rules="required"
              >
                <v-select
                  id="yoeList"
                  v-model="yearsOfExperience"
                  :dir="
                    $store.state.appConfig.isRTL
                      ? 'rtl'
                      : 'ltr'
                  "
                  label="title"
                  :options="
                    yearsOfExperienceOptions
                  "
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label-for="religionList"
              label="Religion"
            >
              <validation-provider
                #default="{ errors }"
                name="Religion"
                rules="required"
              >
                <v-select
                  id="religionList"
                  v-model="religion"
                  :dir="
                    $store.state.appConfig.isRTL
                      ? 'rtl'
                      : 'ltr'
                  "
                  label="name"
                  :options="religionOption"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Birth Date"
              label-for="birthdate"
            >
              <validation-provider
                #default="{ errors }"
                name="Birth Date"
                rules="required"
              >
                <flat-pickr
                  v-model="birthdate"
                  class="form-control"
                  name="birthdate"
                  placeholder="Birth date"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  :config="{
                    altInput: true,
                    altFormat: 'F j, Y',
                    dateFormat: 'Y-m-d',
                    disable: [
                      {
                        from: minDate,
                        to: minUpperDate,
                      },
                      {
                        from: maxDate,
                        to: maxUpperDate,
                      },
                    ],
                  }"
                  :min="minDate"
                  :max="maxDate"
                />
                <small
                  v-if="errors.length > 0"
                  class="text-danger"
                >{{ errors[0] }}<br /></small>
                <small
                  class="text-secondary"
                >You must be at least 18 years
                  old</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              name="Marriage Status"
              rules="required"
            >
              <b-form-checkbox
                v-model="married"
                name="married"
                value="Married"
              >
                Married
              </b-form-checkbox>
              <b-form-checkbox
                v-model="married"
                class="mt-1"
                name="married"
                value="Single"
              >
                Single
              </b-form-checkbox>
              <small class="text-danger">{{
                errors[0]
              }}</small>
            </validation-provider>
          </b-col>

          <!-- divider -->
          <b-col sm="12">
            <div class="divider my-2">
              <div class="divider-text">
                Residence
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="countyList"
              label="County"
            >
              <validation-provider
                #default="{ errors }"
                name="County"
                rules="required"
              >
                <v-select
                  id="countyList"
                  v-model="county"
                  :dir="
                    $store.state.appConfig.isRTL
                      ? 'rtl'
                      : 'ltr'
                  "
                  label="name"
                  :options="countyOption"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label-for="subCountyList"
              label="Sub County"
            >
              <validation-provider
                #default="{ errors }"
                name="Sub County"
                rules="required"
              >
                <v-select
                  id="subCountyList"
                  v-model="subcounty"
                  :dir="
                    $store.state.appConfig.isRTL
                      ? 'rtl'
                      : 'ltr'
                  "
                  label="name"
                  :options="
                    county
                      ? county.sub_counties
                      : []
                  "
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Ward"
              label-for="ward"
            >
              <validation-provider
                #default="{ errors }"
                name="Ward"
                rules="required"
              >
                <b-form-input
                  id="ward"
                  v-model="ward"
                  name="ward"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  placeholder="Ward"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- divider -->
          <b-col sm="12">
            <div class="divider my-2">
              <div class="divider-text">
                Address
              </div>
            </div>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Postal Address"
              label-for="street_address"
            >
              <validation-provider
                #default="{ errors }"
                name="Postal Address"
                rules="required|integer"
              >
                <b-form-input
                  id="street_address"
                  v-model="address.street_address"
                  name="street_address"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  placeholder="Postal Address"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label-for="postalList"
              label="Postal Code"
            >
              <validation-provider
                #default="{ errors }"
                name="Postal Code"
                rules="required"
              >
                <v-select
                  id="postalList"
                  v-model="postal"
                  :dir="
                    $store.state.appConfig.isRTL
                      ? 'rtl'
                      : 'ltr'
                  "
                  label="code"
                  :options="postalOption"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label-for="postalLocalityList"
              label="Postal City/Town"
            >
              <validation-provider
                #default="{ errors }"
                name="Postal Town/City"
                rules="required"
              >
                <v-select
                  id="postalLocalityList"
                  v-model="postal"
                  :dir="
                    $store.state.appConfig.isRTL
                      ? 'rtl'
                      : 'ltr'
                  "
                  label="locality"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  :options="postalOption"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- divider -->
          <b-col sm="12">
            <div class="divider my-2">
              <div class="divider-text">
                Other
              </div>
            </div>
          </b-col>

          <b-col sm="6">
            <b-form-checkbox
              v-model="disabled"
              class="mt-2"
              name="disabled"
            >
              Are you disabled?
            </b-form-checkbox>
          </b-col>

          <b-col sm="6">
            <b-form-group
              v-if="disabled"
              label="Disablity"
              label-for="disability"
            >
              <validation-provider
                #default="{ errors }"
                name="Disablity"
                rules="required|alpha-dash"
              >
                <b-form-input
                  id="disability"
                  v-model="disability"
                  name="disability"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  placeholder="Disablity"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="disabled"
            sm="6"
          >
            <b-form-checkbox
              v-model="pwdRegistered"
              class="mt-2"
              name="pwdRegistered"
            >
              PWD Registered
            </b-form-checkbox>
          </b-col>

          <b-col
            v-if="disabled && pwdRegistered"
            sm="6"
          >
            <b-form-group
              label="PWD Registered Number"
              label-for="pwdRegNumber"
            >
              <validation-provider
                #default="{ errors }"
                name="PWD Registered Number"
                rules="required"
              >
                <b-form-input
                  id="pwdRegNumber"
                  v-model="pwdRegNumber"
                  name="pwdRegNumber"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  placeholder="PWD Registered Number"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="
                'rgba(255, 255, 255, 0.15)'
              "
              variant="primary"
              class="mt-1 mr-1"
              :disabled="loading || loadingFirst"
              @click.prevent="saveChanges"
            >
              <b-spinner
                v-if="loading || loadingFirst"
                small
                type="grow"
              />
              <span
                v-if="!loading || !loadingFirst"
              >
                Save changes</span>
              <span v-if="loading">
                Saving changes</span>
            </b-button>
            <b-button
              v-ripple.400="
                'rgba(186, 191, 199, 0.15)'
              "
              type="reset"
              class="mt-1"
              variant="outline-secondary"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
/* eslint-disable-next-line func-names */
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
// eslint-disable-next-line object-curly-newline
import {
  required,
  email,
  integer,
  length,
  min,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import countries from './countries.json'
import counties from './counties.json'
import religions from './religions.json'
import postal from './postal.json'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BSpinner,
    vSelect,
    flatPickr,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const now = new Date()
    const today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
    )
    // 15th two months prior
    const minDate = new Date(today)
    minDate.setFullYear(
      minDate.getFullYear() - 2000,
    )

    const minUpperDate = new Date(today)
    minUpperDate.setFullYear(
      minUpperDate.getFullYear() - 80,
    )
    const maxDate = new Date(today)
    maxDate.setFullYear(
      maxDate.getFullYear() - 18,
    )

    const maxUpperDate = new Date(today)
    maxUpperDate.setFullYear(
      maxUpperDate.getFullYear() + 2000,
    )

    return {
      loadingFirst: false,
      loading: false,
      countryOption: countries,
      countyOption: counties,
      religionOption: religions,
      yearsOfExperienceOptions: [
        '<1',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8+',
      ],
      postalOption: postal,

      country: null,
      county: null,
      subcounty: null,
      ward: null,
      religion: null,
      nationalID: null,
      married: false,
      disabled: false,
      disability: null,
      pwdRegistered: false,
      pwdRegNumber: null,
      yearsOfExperience: null,

      birthdate: null,
      postal: null,
      address: {
        street_address: null,
        locality: null,
        region: null,
        postal_code: null,
        country: null,
      },

      minDate,
      maxUpperDate,
      maxDate,
      minUpperDate,

      // validation
      required,
      email,
      integer,
      length,
      min,
    }
  },
  watch: {
    county() {
      if (!this.loadingFirst) {
        this.subcounty = null
      }
    },
    // eslint-disable-next-line space-before-function-paren
    '$store.auth.userInformation': function () {
      const {
        county,
        sub_county,
        ward,
        religion,
        nationalID,
        married,
        disabled,
        disability,
        pwdRegistered,
        pwdRegNumber,
        yearsOfExperience,
      } = this.$store.state.auth.userInformation
      this.county = this.countyOption.find(
        e => e.name === county,
      )
      // eslint-disable-next-line camelcase
      this.subcounty = sub_county
      this.ward = ward
      this.religion = this.religionOption.find(
        e => e.name === religion,
      )
      this.nationalID = nationalID
      this.married = married
      this.disabled = disabled
      this.disability = disability
      this.pwdRegistered = pwdRegistered
      this.pwdRegNumber = pwdRegNumber
      this.yearsOfExperience = yearsOfExperience

      const { birthdate, address } =
        this.$store.state.auth.user

      this.birthdate = birthdate
      this.address = address
      this.country = this.countryOption.find(
        // eslint-disable-next-line comma-dangle
        e => e.countryName === address.country,
      )
      this.postal = this.postalOption.find(
        // eslint-disable-next-line comma-dangle
        e => e.code === address.postal_code,
      )
    },
  },
  created() {
    this.$store
      .dispatch('auth/getUserInformation')
      .then(() => {
        this.loadingFirst = true
        const {
          county,
          sub_county,
          ward,
          religion,
          nationalID,
          married,
          disabled,
          disability,
          pwdRegistered,
          pwdRegNumber,
          yearsOfExperience,
        } = this.$store.state.auth.userInformation

        this.county = this.countyOption.find(
          e => e.name === county,
        )
        // eslint-disable-next-line camelcase
        this.subcounty = sub_county
        this.ward = ward
        this.religion = this.religionOption.find(
          e => e.name === religion,
        )
        this.nationalID = nationalID
        this.married = married
        this.disabled = disabled
        this.disability = disability
        this.pwdRegistered = pwdRegistered
        this.pwdRegNumber = pwdRegNumber
        this.yearsOfExperience = yearsOfExperience

        const { birthdate, address } =
          this.$store.state.auth.user

        this.birthdate = birthdate
        this.address = address
        this.country = this.countryOption.find(
          // eslint-disable-next-line comma-dangle
          e => e.countryName === address.country,
        )
        this.postal = this.postalOption.find(
          // eslint-disable-next-line comma-dangle
          e => e.code === address.postal_code,
        )
        setTimeout(() => {
          this.loadingFirst = false
        }, 1000)
      })
      .catch(() => {
        this.loadingFirst = false
        this.showToast(
          'Opps!',
          'Failed to fetch your personal information. Try again later.',
          'Bell',
          'danger',
          // eslint-disable-next-line comma-dangle
          15000,
        )
      })
  },
  methods: {
    resetForm() {
      this.county = null
      this.subcounty = null
      this.ward = null
      this.religion = null
      this.nationalID = null
      this.married = 'Single'
      this.disabled = false
      this.disability = null
      this.pwdRegistered = false
      this.pwdRegNumber = null
      this.yearsOfExperience = null

      this.birthdate = null
      this.postal = null
      this.address = {
        street_address: null,
        locality: null,
        region: null,
        postal_code: null,
        country: null,
      }
    },
    showToast(
      title,
      text,
      icon,
      variant,
      timeout = 10000,
    ) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    saveChanges() {
      this.$refs.updateInformationForm
        .validate()
        .then(async success => {
          if (success) {
            this.loading = true
            await this.$store
              .dispatch(
                'auth/updateUserInformation',
                {
                  birthdate: this.birthdate,
                  address: {
                    street_address:
                      this.address.street_address.trim(),
                    locality:
                      this.postal.locality.trim(),
                    postal_code:
                      this.postal.code.trim(),
                    country:
                      this.country.countryName,
                  },
                  county: this.county.name.trim(),
                  sub_county:
                    this.subcounty.trim(),
                  ward: this.ward.trim(),
                  religion:
                    this.religion.name.trim(),
                  nationalID:
                    this.nationalID.trim(),
                  married:
                    this.married || 'Single',
                  disabled: this.disabled,
                  disability: this.disability,
                  pwdRegistered:
                    this.pwdRegistered,
                  pwdRegNumber: this.pwdRegNumber,
                  yearsOfExperience:
                    this.yearsOfExperience.toString(),
                },
              )
              .then(res => {
                this.showToast(
                  'Success',
                  'Profile information successfully processed.',
                  'Check',
                  'success',
                  // eslint-disable-next-line comma-dangle
                  15000,
                )
                this.$store.commit(
                  'auth/UPDATE_AUTH_USER',
                  res.data.data,
                )
                this.optionsLocal = res.data.data
                this.loading = false
              })
              .catch(err => {
                this.loading = false
                if (err.response) {
                  if ('data' in err.response) {
                    if (
                      'data' in err.response.data
                    ) {
                      if (
                        Array.isArray(
                          err.response.data.data,
                        )
                      ) {
                        err.response.data.data.forEach(
                          param => {
                            this.showToast(
                              `Error ${err.response.status}`,
                              param.message,
                              'X',
                              'danger',
                              // eslint-disable-next-line comma-dangle
                              15000,
                            )
                          },
                        )
                      }
                    } else {
                      this.showToast(
                        `Error ${err.response.status}`,
                        `${err.response.data.message}`,
                        'X',
                        'danger',
                        // eslint-disable-next-line comma-dangle
                        15000,
                      )
                    }
                  } else {
                    this.showToast(
                      `Error ${err.response.status}`,
                      `${err.response.statusText}`,
                      'X',
                      'danger',
                      // eslint-disable-next-line comma-dangle
                      15000,
                    )
                    // eslint-disable-next-line function-paren-newline
                  }
                } else {
                  this.showToast(
                    'Error',
                    `${err.message}`,
                    'X',
                    'danger',
                    // eslint-disable-next-line comma-dangle
                    15000,
                  )
                  // eslint-disable-next-line function-paren-newline
                }
              })
          } else {
            this.loading = false
            this.showToast(
              'There seems to be a problem in your submission',
              undefined,
              'X',
              'danger',
              // eslint-disable-next-line comma-dangle
              15000,
            )
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
