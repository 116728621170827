<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="
              require('@/assets/images/avatars/profile.png')
            "
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="
            'rgba(255, 255, 255, 0.15)'
          "
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="
            resendConfirmation,
            $refs.refInputEl.$el.click()
          "
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="
            'rgba(186, 191, 199, 0.15)'
          "
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of
          2Mb</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer ref="updateForm">
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="First Name"
              label-for="given_name"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required|min:3"
              >
                <b-form-input
                  id="given_name"
                  v-model="
                    optionsLocal.given_name
                  "
                  name="given_name"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  placeholder="John"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Middle Name (Optional)"
              label-for="middle_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Middle Name"
                rules="alpha"
              >
                <b-form-input
                  id="middle_name"
                  v-model="
                    optionsLocal.middle_name
                  "
                  name="middle_name"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  placeholder="Doe"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Last Name"
              label-for="family_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required|min:3"
              >
                <b-form-input
                  id="family_name"
                  v-model="
                    optionsLocal.family_name
                  "
                  name="family_name"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  placeholder="Smith"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Username"
              label-for="register-username"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required|alpha-dash|min:3"
              >
                <b-form-input
                  id="register-username"
                  v-model="
                    optionsLocal.preferred_username
                  "
                  name="register-username"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  placeholder="johndoe"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Email Address"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="optionsLocal.email"
                  name="email"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Phone Number"
              label-for="phone_number"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone Number"
                rules="required|integer|length:10|regex:^([0-9]+)$"
              >
                <b-form-input
                  id="phone_number"
                  v-model="
                    optionsLocal.phone_number
                  "
                  name="phone_number"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  placeholder="0xxxxxxxxx"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- alert -->
          <b-col
            v-if="!optionsLocal.email_verified"
            cols="12"
            class="mt-75"
          >
            <b-alert
              show
              variant="warning"
              class="mb-50"
            >
              <h4 class="alert-heading">
                Your email is not confirmed.
                Please check your inbox.
              </h4>
              <div class="alert-body">
                <b-link
                  class="alert-link"
                  @click.stop="resendConfirmation"
                >
                  Resend confirmation
                </b-link>
              </div>
            </b-alert>
          </b-col>
          <!--/ alert -->

          <b-col cols="12">
            <b-button
              v-ripple.400="
                'rgba(255, 255, 255, 0.15)'
              "
              variant="primary"
              class="mt-2 mr-1"
              :disabled="loading"
              @click.prevent="saveChanges"
            >
              <b-spinner
                v-if="loading"
                small
                type="grow"
              />
              <span v-if="!loading">
                Save changes</span>
              <span v-if="loading">
                Saving changes</span>
            </b-button>
            <b-button
              v-if="!dataMatches"
              v-ripple.400="
                'rgba(186, 191, 199, 0.15)'
              "
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
// eslint-disable-next-line object-curly-newline
import {
  required,
  email,
  integer,
  length,
  min,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      optionsLocal: {},
      profileFile: null,

      loading: false,
      given_name: '',
      middle_name: '',
      family_name: '',
      phone_number: '',
      gender: '',
      username: '',
      userEmail: '',
      password: '',
      // validation
      required,
      email,
      integer,
      length,
      min,
    }
  },
  computed: {
    dataMatches() {
      return (
        // eslint-disable-next-line operator-linebreak
        JSON.stringify(this.optionsLocal) ===
        JSON.stringify(
          this.$store.state.auth.user,
        )
      )
    },
  },
  created() {
    const user = this.$store.state.auth.user || {}
    this.optionsLocal = user
  },
  methods: {
    resetForm() {
      const user =
        this.$store.state.auth.user || {}
      this.optionsLocal = user
    },
    showToast(
      title,
      text,
      icon,
      variant,
      timeout = 10000,
    ) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    saveChanges() {
      this.$refs.updateForm
        .validate()
        .then(async success => {
          if (success) {
            this.loading = true
            await this.$store
              .dispatch('auth/updateUserInfo', {
                given_name:
                  this.optionsLocal.given_name,
                middle_name:
                  this.optionsLocal.middle_name,
                family_name:
                  this.optionsLocal.family_name,
                preferred_username:
                  this.optionsLocal
                    .preferred_username,
                email: this.optionsLocal.email,
                gender: this.optionsLocal.gender,
                phone_number:
                  this.optionsLocal.phone_number,
              })
              .then(res => {
                this.showToast(
                  'Success',
                  'Profile update successfully processed.',
                  'Check',
                  'success',
                  // eslint-disable-next-line comma-dangle
                  15000,
                )
                this.$store.commit(
                  'auth/UPDATE_AUTH_USER',
                  res.data.data,
                )
                this.optionsLocal = res.data.data
                this.loading = false
              })
              .catch(err => {
                this.loading = false
                if (err.response) {
                  if ('data' in err.response) {
                    if (
                      'data' in err.response.data
                    ) {
                      if (
                        Array.isArray(
                          err.response.data.data,
                        )
                      ) {
                        err.response.data.data.forEach(
                          param => {
                            this.showToast(
                              `Error ${err.response.status}`,
                              param.message,
                              'X',
                              'danger',
                              // eslint-disable-next-line comma-dangle
                              15000,
                            )
                          },
                        )
                      }
                    } else {
                      this.showToast(
                        `Error ${err.response.status}`,
                        `${err.response.data.message}`,
                        'X',
                        'danger',
                        // eslint-disable-next-line comma-dangle
                        15000,
                      )
                    }
                  } else {
                    this.showToast(
                      `Error ${err.response.status}`,
                      `${err.response.statusText}`,
                      'X',
                      'danger',
                      // eslint-disable-next-line comma-dangle
                      15000,
                    )
                    // eslint-disable-next-line function-paren-newline
                  }
                } else {
                  this.showToast(
                    'Error',
                    `${err.message}`,
                    'X',
                    'danger',
                    // eslint-disable-next-line comma-dangle
                    15000,
                  )
                  // eslint-disable-next-line function-paren-newline
                }
              })
          } else {
            this.loading = false
            this.showToast(
              'There seems to be a problem in your submission',
              undefined,
              'X',
              'danger',
              // eslint-disable-next-line comma-dangle
              15000,
            )
          }
        })
    },
    resendConfirmation() {
      // eslint-disable-next-line implicit-arrow-linebreak
      this.showToast(
        'Option Unavailable',
        'Sorry, this option is currently unavailable. Please check back later.',
        'Bell',
        'danger',
        // eslint-disable-next-line comma-dangle
        15000,
        // eslint-disable-next-line comma-dangle
      )
      // eslint-disable-next-line function-paren-newline
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } =
      useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
