<template>
  <b-card title="Education">
    <b-card-text
      v-if="educationCombined.length < 1"
    >Start by adding your education. Click the 'add item' button to
      start.</b-card-text>
    <b-card-text>Make sure to indicate the details correctly.</b-card-text>
    <validation-observer ref="updateEducation">
      <b-form>
        <b-card-body class="invoice-padding form-item-section">
          <div
            ref="form"
            class="repeater-form"
            :style="{ height: trHeight }"
          >
            <b-row
              v-for="(item, index) in educationCombined"
              :key="index"
              ref="row"
              class="pb-2"
            >
              <!-- Item Form -->
              <!-- ? This will be in loop => So consider below markup for single item -->
              <b-col cols="12">
                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                <div class="d-none d-lg-flex">
                  <div class="form-item-action-col" />
                </div>

                <!-- Form Input Fields OR content inside bordered area  -->
                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                <div class="d-flex border rounded">
                  <b-row class="flex-grow-1 p-2">
                    <!-- Single Item Form Headers -->
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <label class="d-inline d-lg-none">Level</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Level"
                        rules="required"
                      >
                        <v-select
                          v-model="item.level"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="educationOptions"
                          label="name"
                          :clearable="false"
                          class="mb-1 item-selector-title"
                          placeholder="Select Level"
                          :disabled="item.fromDB"
                          :selectable="option => isAvailable(option)"
                          @input="val => updateLevelForm(index, val)"
                        />
                        <small class="text-danger mb-2">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <label class="d-inline d-lg-none">Certificate</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Certificate"
                        rules="required"
                      >
                        <v-select
                          v-model="item.examination"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="item.level ? item.level.examinations : []"
                          label="name"
                          :clearable="false"
                          class="mb-1 item-selector-title"
                          placeholder="Select Certificate"
                          :disabled="item.fromDB"
                          @input="val => updateSelectedExaminations(index, val)"
                        />
                        <small class="text-danger mb-2">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <label class="d-inline d-lg-none">Year Started</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Year Started"
                        rules="required|integer|length:4"
                      >
                        <b-form-input
                          :id="`ys-${index}`"
                          v-model="item.ys"
                          placeholder="Year Started"
                          type="number"
                          class="mb-1"
                          :disabled="item.fromDB"
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <label class="d-inline d-lg-none">Year Completed</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Year Completed"
                        :rules="`required|integer|length:4`"
                      >
                        <b-form-input
                          :id="`yc-${index}`"
                          v-model="item.yc"
                          placeholder="Year Completed"
                          type="number"
                          class="mb-1"
                          :disabled="item.fromDB"
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      lg="5"
                    >
                      <label class="d-inline d-lg-none">Institution</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Institution"
                        rules="required"
                      >
                        <b-form-input
                          :id="`institution-${index}`"
                          v-model="item.institution"
                          placeholder="Institution"
                          class="mb-1"
                          :disabled="item.fromDB"
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <label class="d-inline d-lg-none">Grade</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Grade"
                        rules="required"
                      >
                        <b-form-input
                          :id="`grade-${index}`"
                          v-model="item.grade"
                          placeholder="Grade"
                          class="mb-1"
                          :disabled="item.fromDB"
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <label class="d-inline d-lg-none">Certificate NO.</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Certificate Number"
                        rules="required"
                      >
                        <b-form-input
                          :id="`cert_no-${index}`"
                          v-model="item.cert_no"
                          placeholder="Cert No."
                          class="mb-1"
                          :disabled="item.fromDB"
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </b-col>

                    <b-col
                      v-if="!item.fromDB"
                      cols="12"
                      lg="12"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Certificate Upload"
                        rules="required"
                      >
                        <b-form-file
                          :id="`cert_file_${index}`"
                          v-model="item.file"
                          accept=".jpg, .png, .gif, .pdf"
                          :state="
                            errors.length > 0
                              ? false
                              : null || Boolean(item.file)
                          "
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          :disabled="item.fromDB"
                          @input="validateFile(index)"
                        >
                          <template
                            slot="file-name"
                            slot-scope="{ names }"
                          >
                            <b-badge variant="primary">
                              {{ names[0] }}
                            </b-badge>
                            <b-badge
                              v-if="names.length > 1"
                              variant="dark"
                              class="ml-1"
                            >
                              +
                              {{ names.length - 1 }}
                              More files
                            </b-badge>
                          </template>
                        </b-form-file>
                        <small class="text-danger mb-2">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <div
                    v-if="item.canBeDeleted !== false"
                    class="
                      d-flex
                      flex-column
                      justify-content-between
                      border-left
                      py-50
                      px-25
                    "
                  >
                    <feather-icon
                      size="16"
                      icon="XIcon"
                      class="cursor-pointer"
                      @click="removeItem(index)"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            :disabled="loading || loadingFirst"
            @click="addNewItemInItemForm"
          >
            <b-spinner
              v-if="loading || loadingFirst"
              small
              type="grow"
            />
            Add Item
          </b-button>
        </b-card-body>

        <b-button
          v-if="education.list.length > 0"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-1 mr-1"
          :disabled="loading || loadingFirst"
          @click.prevent="saveChanges"
        >
          <b-spinner
            v-if="loading || loadingFirst"
            small
            type="grow"
          />
          <span v-if="!loading"> Save changes</span>
        </b-button>
        <b-alert
          variant="danger"
          show
          class="mt-2"
        >
          <h4 class="alert-heading">
            <feather-icon
              class="mr-25"
              icon="AlertTriangleIcon"
            />
            Warning
          </h4>
          <div class="alert-body">
            <span>Please do not close this page while it is uploading your
              documents. Wait until they have been successfully uploaded.</span>
          </div>
        </b-alert>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
/* eslint-disable space-before-function-paren */
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import {
  BButton,
  BForm,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BFormFile,
  BBadge,
  BAlert,
  //   BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line object-curly-newline
import { required, email, integer, length, min } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import educationLevels from './educationLevels.json'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BFormFile,
    BBadge,
    // BFormCheckbox,
    BSpinner,
    vSelect,
    BAlert,
    // flatPickr,
    // // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadingFirst: true,
      loading: false,
      hasError: false,
      fileSizeLimit: 2 * 1000 * 1000,

      // education: [],
      educationOptions: educationLevels,

      selectedLevels: [],
      selectedExaminations: [],

      // validation
      required,
      email,
      integer,
      length,
      min,
    }
  },
  computed: {
    educationCombined() {
      return this.education.available.concat(this.education.list)
    },
  },
  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'education.available': function () {
      this.initTrHeight()
    },
    // eslint-disable-next-line func-names
    'education.list': function () {
      this.initTrHeight()
    },
    // eslint-disable-next-line func-names
    '$refs.form.scrollHeight': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    this.loadingFirst = true
    window.addEventListener('resize', this.initTrHeight)
    setTimeout(() => {
      this.$store.dispatch('auth/getUserEducation').then(() => {
        this.$store.state.auth.userEducation.forEach(row => {
          const level = this.educationOptions.find(e => e.name === row.level)
          this.education.available.push({
            level,
            examination: level.examinations.find(
              // eslint-disable-next-line comma-dangle
              e => e.name === row.examination,
            ),
            ys: row.year_started,
            yc: row.year_completed,
            institution: row.institution,
            grade: row.grade,
            cert_no: row.cert_no,
            fromDB: true,
            canBeDeleted: false,
          })
          this.initTrHeight()
        })
      })
      setTimeout(() => {
        this.loadingFirst = false
      }, 1500)
    }, 1000)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    showToast(title, text, icon, variant, timeout = 10000) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    validateFile(i) {
      // eslint-disable-next-line operator-linebreak
      const index = i
      const { file } = this.educationCombined[index]
      if (
        // eslint-disable-next-line operator-linebreak
        file !== null &&
        file.size > this.fileSizeLimit
      ) {
        this.showToast(
          'Invalid Upload',
          `Files should have a maximum size of 2MB. You selected a file which is ${
            // eslint-disable-next-line operator-linebreak
            (file.size / (1000 * 1000)).toFixed(2)
          } MB`,
          'X',
          'warning',
          // eslint-disable-next-line comma-dangle
          10000,
        )
        this.educationCombined[index].file = null
      } else if (this.educationCombined[index].file !== null) {
        // eslint-disable-next-line object-curly-newline
        const { size, lastModified, name, type } =
          this.educationCombined[index].file
        this.educationCombined[index].fileMetaData = {
          size,
          lastModified,
          name,
          type,
        }
        this.fileToBase64(index)
      }
    },
    fileToBase64(index) {
      const reader = new FileReader()
      reader.readAsDataURL(this.educationCombined[index].file)
      reader.onload = () => {
        const { result } = reader
        this.educationCombined[index].fileEncoded = result
      }
      // eslint-disable-next-line func-names
      reader.onerror = function () {}
    },
    updateLevelForm(i, val) {
      // eslint-disable-next-line operator-linebreak
      const { examinations, requires, name } = val
      this.educationCombined[i].examinations = examinations
      setTimeout(() => {
        this.educationCombined[i].examination = null
      }, 500)

      if (requires !== null) {
        // search list for requires
        const ids = []
        this.educationCombined.forEach(value => {
          if (!ids.includes(value.level.id)) {
            ids.push(value.level.id)
          }
        })
        requires.forEach(req => {
          if (!ids.includes(req)) {
            this.educationCombined[i].level = null
            this.showToast(
              'Invalid Selection',
              `You need a '${req}' level to add '${name}'`,
              'X',
              'warning',
              // eslint-disable-next-line comma-dangle
              10000,
            )
          }
        })
      }
      // this.selectedLevels.push(level.id)
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.$refs.updateEducation.validate().then(async success => {
        if (success) {
          this.hasError = false
          this.$refs.form.style.overflow = 'hidden'
          this.education.list.push(
            // eslint-disable-next-line comma-dangle
            JSON.parse(JSON.stringify(this.itemFormBlankItem)),
          )

          this.$nextTick(() => {
            this.trAddHeight(this.$refs.row[0].offsetHeight)
            setTimeout(() => {
              this.$refs.form.style.overflow = null
            }, 350)
          })
        } else {
          if (!this.hasError) {
            this.$nextTick(() => {
              this.trAddHeight(this.$refs.row[0].offsetHeight / 5)
              setTimeout(() => {
                this.$refs.form.style.overflow = null
              }, 350)
            })
          }
          this.hasError = true
          this.showToast(
            'Opps!',
            'Please correct errors before adding a new row',
            'X',
            'danger',
            // eslint-disable-next-line comma-dangle
            8000,
          )
        }
      })
    },
    isAvailable(option) {
      if (option.max) {
        const count = this.educationCombined.filter(v => {
          if (v.level == null) return false
          return v.level.id === option.id
        })
        if (count.length >= option.max) {
          return false
        }
        return true
      }
      return true
    },
    updateSelectedExaminations(index, val) {
      const selectedSimilar = this.selectedExaminations.filter(
        // eslint-disable-next-line comma-dangle
        e => e.name === val.name,
      )
      const examination = this.educationCombined[index].examinations.find(
        // eslint-disable-next-line comma-dangle
        e => e.name === val.name,
      )
      if (selectedSimilar >= examination.max) {
        // maximum iterations reached
      } else {
        this.selectedExaminations.push({
          name: examination.name,
          index,
        })
      }
    },
    removeItem(index) {
      // check and remove items that require the removed index
      if (!this.educationCombined[index].fromDB) {
        const ids = []
        const realIndex = index - this.education.available.length
        ids.push(realIndex)
        if (this.educationCombined[index].level !== null) {
          const cid = this.educationCombined[index].level.id
          this.educationCombined.forEach((value, i) => {
            if (value.level !== null) {
              if (
                // eslint-disable-next-line operator-linebreak
                value.level.requires !== null &&
                value.level.requires.includes(cid)
              ) {
                ids.push(value.level.id)
                this.showToast(
                  'Invalid Selection',
                  `You need a '${this.educationCombined[index].level.name}' level in '${value.level.name}' level. This level will also be removed.`,
                  'X',
                  'warning',
                  // eslint-disable-next-line comma-dangle
                  10000,
                )
              }
            } else {
              ids.push(i)
            }
          })
        }
        ids.sort((a, b) => b - a).forEach(v => this.education.list.splice(v, 1))
        this.trTrimHeight(this.$refs.row[0].offsetHeight)
      } else {
        this.showToast(
          'Invalid Action',
          'You are not allowed to delete this row',
          'X',
          'warning',
          // eslint-disable-next-line comma-dangle
          10000,
        )
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
    saveChanges() {
      this.$refs.updateEducation.validate().then(async success => {
        if (success) {
          this.loading = true
          const education = []
          this.education.list.forEach(value => {
            if (value.level != null) {
              education.push({
                level: value.level.name,
                examination: value.examination.name,
                institution: value.institution,
                year_started: value.ys,
                year_completed: value.yc,
                grade: value.grade,
                cert_no: value.cert_no,
                file: value.fileEncoded,
                fileMetaData: value.fileMetaData,
              })
            }
          })
          await this.$store
            .dispatch('auth/updateUserEducation', { education })
            .then(res => {
              this.education.available = res
              this.education.list = []
              this.showToast(
                'Success',
                'Education data successfully processed.',
                'Check',
                'success',
                // eslint-disable-next-line comma-dangle
                15000,
              )
              this.loading = false
            })
            .catch(err => {
              this.loading = false
              if (err.response) {
                if ('data' in err.response) {
                  if ('data' in err.response.data) {
                    if (Array.isArray(err.response.data.data)) {
                      err.response.data.data.forEach(param => {
                        this.showToast(
                          `Error ${err.response.status}`,
                          param.message,
                          'X',
                          'danger',
                          // eslint-disable-next-line comma-dangle
                          15000,
                        )
                      })
                    }
                  } else {
                    this.showToast(
                      `Error ${err.response.status}`,
                      `${err.response.data.message}`,
                      'X',
                      'danger',
                      // eslint-disable-next-line comma-dangle
                      15000,
                    )
                  }
                } else {
                  this.showToast(
                    `Error ${err.response.status}`,
                    `${err.response.statusText}`,
                    'X',
                    'danger',
                    // eslint-disable-next-line comma-dangle
                    15000,
                  )
                  // eslint-disable-next-line function-paren-newline
                }
              } else {
                this.showToast(
                  'Error',
                  `${err.message}`,
                  'X',
                  'danger',
                  // eslint-disable-next-line comma-dangle
                  15000,
                )
                // eslint-disable-next-line function-paren-newline
              }
            })
        } else {
          this.loading = false
          this.showToast(
            'There seems to be a problem in your submission',
            undefined,
            'X',
            'danger',
            // eslint-disable-next-line comma-dangle
            15000,
          )
        }
      })
    },
  },
  setup() {
    // const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // // Register module
    // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    // })

    const education = ref({
      list: [],
      available: [],
    })

    const itemFormBlankItem = {
      level: null,
      examination: null,
      grade: null,
      institution: null,
      ys: null,
      yc: null,
      cert_no: null,
      fromDB: false,
      canBeDeleted: true,
    }

    return {
      education,
      itemFormBlankItem,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
