var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Document Storage"}},[_c('b-card-text',[_vm._v("Upload and store your documents here and use them for future applications.")]),_c('b-row',[_c('b-col',{attrs:{"lg":"8","sm":"4"}},[_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Note ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" Certificates you uploaded in the education tab cannot be deleted here. The description will help you identify documents within the system. ")])])],1),_c('b-col',{attrs:{"lg":"4","sm":"4"}},[_c('statistic-card-horizontal',{attrs:{"icon":"ServerIcon","color":"success","statistic":(((_vm.stats.totalSize / 1000000).toFixed(2).toLocaleString()) + " MB"),"statistic-title":"Storage Usage"}})],1)],1),_c('b-card-body',{staticClass:"invoice-padding form-item-section"},[_c('div',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight })},_vm._l((_vm.documents.list),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,staticClass:"pb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-none d-lg-flex"},[_c('div',{staticClass:"form-item-action-col"})]),_c('div',{staticClass:"d-flex border rounded"},[_c('b-row',{staticClass:"flex-grow-1 p-2"},[_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('validation-observer',{ref:("updateDocuments-" + index),refInFor:true},[_c('b-form',[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("Description")]),_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":("description-" + index),"placeholder":"Description","state":errors.length > 0 ? false : null},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),(item.status.cloud)?_c('b-input-group-append',[_c('b-button',{attrs:{"variant":("outline-" + (item.status.loading ? 'warning' : 'success')),"disabled":item.status.loading},on:{"click":function($event){$event.preventDefault();return _vm.updateDocumentDescription(index)}}},[(item.status.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(!item.status.loading)?_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}):_vm._e()],1)],1):_vm._e()],1),_c('small',{staticClass:"text-danger mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),(!item.status.cloud)?_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":"Document Upload","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":("document_" + index),"accept":".jpg, .png, .gif, .pdf, .doc, .docx, .txt","state":errors.length > 0 ? false : null || Boolean(item.file),"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"input":function($event){return _vm.validateFile(index)}},scopedSlots:_vm._u([{key:"file-name",fn:function(ref){
                              var names = ref.names;
return [_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(names[0])+" ")]),(names.length > 1)?_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"dark"}},[_vm._v(" + "+_vm._s(names.length - 1)+" More files ")]):_vm._e()]}}],null,true),model:{value:(item.file),callback:function ($$v) {_vm.$set(item, "file", $$v)},expression:"item.file"}}),_c('small',{staticClass:"text-danger mb-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(item.status.cloud)?_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('small',[_vm._v(_vm._s((((item.size / 1000000).toFixed(2).toLocaleString()) + " MB"))+" "),_c('strong',[_vm._v("·")]),_vm._v(" "+_vm._s(_vm.formatDate(item.updatedAt, { month: 'short', day: 'numeric', weekday: 'short', }))+" "),_c('strong',[_vm._v("·")]),_vm._v(" "+_vm._s(item.endpoint ? item.endpoint : 'Education')+"/"+_vm._s(item.name))]),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[(false)?_c('b-button',{staticClass:"mr-3",attrs:{"variant":"outline-success","pill":""}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"DownloadIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Download")])],1):_vm._e(),(!item.adminRequired)?_c('b-button',{attrs:{"variant":"outline-danger","pill":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteDocument(index)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Trash2Icon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Delete")])],1):_vm._e()],1)],1)],1):_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12","lg":"12"}},[_c('b-card-text',[_vm._v(_vm._s(item.description && item.file ? "File ready to be uploaded. Click upload button below to upload file to the cloud." : "Enter a description and choose a file to upload to the cloud."))]),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"variant":"outline-secondary","pill":"","disabled":item.description === null ||
                          item.file === null ||
                          item.status.loading},on:{"click":function($event){return _vm.uploadDocument(index)}}},[(item.status.loading)?_c('b-spinner',{staticClass:"mr-50",attrs:{"small":"","type":"grow"}}):_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UploadIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Upload")])],1)],1)],1)],1)],1),(!item.status.cloud)?_c('div',{staticClass:"\n                d-flex\n                flex-column\n                justify-content-between\n                border-left\n                py-50\n                px-25\n              "},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"size":"16","icon":"XIcon"},on:{"click":function($event){return _vm.removeItem(index)}}})],1):_vm._e()],1)])],1)}),1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"size":"sm","variant":"primary","disabled":_vm.loading || _vm.loadingFirst},on:{"click":_vm.addNewItemInItemForm}},[(_vm.loading || _vm.loadingFirst)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),_vm._v(" Add Item ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }