var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Referees"}},[_c('b-card-text',[_vm._v("Make sure to indicate the details correctly.")]),_c('validation-observer',{ref:"updateReferees"},[_c('b-form',[_c('b-card-body',{staticClass:"invoice-padding form-item-section"},[_c('div',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight })},_vm._l((_vm.referees.list),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,staticClass:"pb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-none d-lg-flex"},[_c('div',{staticClass:"form-item-action-col"})]),_c('div',{staticClass:"d-flex border rounded"},[_c('b-row',{staticClass:"flex-grow-1 p-2"},[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("Name")]),_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":("name-" + index),"placeholder":"Name","state":errors.length > 0 ? false : null},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('small',{staticClass:"text-danger mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("Company")]),_c('validation-provider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":("company-" + index),"placeholder":"Company","state":errors.length > 0 ? false : null},model:{value:(item.company),callback:function ($$v) {_vm.$set(item, "company", $$v)},expression:"item.company"}}),_c('small',{staticClass:"text-danger mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("Designation")]),_c('validation-provider',{attrs:{"name":"Designation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":("designation-" + index),"placeholder":"Designation","state":errors.length > 0 ? false : null},model:{value:(item.designation),callback:function ($$v) {_vm.$set(item, "designation", $$v)},expression:"item.designation"}}),_c('small',{staticClass:"text-danger mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("Phone")]),_c('validation-provider',{attrs:{"name":"Phone","rules":"required|integer|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":("phone-" + index),"placeholder":"Phone","state":errors.length > 0 ? false : null},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}}),_c('small',{staticClass:"text-danger mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("Email")]),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":("Email-" + index),"placeholder":"Email","state":errors.length > 0 ? false : null},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}}),_c('small',{staticClass:"text-danger mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"\n                    d-flex\n                    flex-column\n                    justify-content-between\n                    border-left\n                    py-50\n                    px-25\n                  "},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"size":"16","icon":"XIcon"},on:{"click":function($event){return _vm.removeItem(index)}}})],1)],1)])],1)}),1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"size":"sm","variant":"primary","disabled":_vm.loading || _vm.loadingFirst},on:{"click":_vm.addNewItemInItemForm}},[(_vm.loading || _vm.loadingFirst)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),_vm._v(" Add Item ")],1)],1),(_vm.referees.list.length > 0)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary","disabled":_vm.loading || _vm.loadingFirst},on:{"click":function($event){$event.preventDefault();return _vm.saveChanges.apply(null, arguments)}}},[(_vm.loading || _vm.loadingFirst)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),(!_vm.loading)?_c('span',[_vm._v(" Save changes")]):_vm._e(),(_vm.loading)?_c('span',[_vm._v(" Saving changes")]):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }