<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="updatePassword">
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Old Password"
              label-for="old_password"
            >
              <validation-provider
                #default="{ errors }"
                name="Old Password"
                rules="required|min:8"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="
                    errors.length > 0
                      ? 'is-invalid'
                      : null
                  "
                >
                  <b-form-input
                    id="old_password"
                    v-model="passwordValueOld"
                    name="old_password"
                    :state="
                      errors.length > 0
                        ? false
                        : null
                    "
                    :type="passwordFieldTypeOld"
                    placeholder="Old Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="
                        passwordToggleIconOld
                      "
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label="New Password"
              label-for="new_password"
            >
              <validation-provider
                #default="{ errors }"
                name="New Password"
                rules="required|min:8"
                vid="Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="
                    errors.length > 0
                      ? 'is-invalid'
                      : null
                  "
                >
                  <b-form-input
                    id="new_password"
                    v-model="newPasswordValue"
                    name="new_password"
                    :state="
                      errors.length > 0
                        ? false
                        : null
                    "
                    :type="passwordFieldTypeNew"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="
                        passwordToggleIconNew
                      "
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label="New Password"
              label-for="retype_new_password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password Confirmation"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="
                    errors.length > 0
                      ? 'is-invalid'
                      : null
                  "
                >
                  <b-form-input
                    id="retype_new_password"
                    v-model="RetypePassword"
                    name="retype_new_password"
                    :state="
                      errors.length > 0
                        ? false
                        : null
                    "
                    :type="
                      passwordFieldTypeRetype
                    "
                    placeholder="Retype New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="
                        passwordToggleIconRetype
                      "
                      class="cursor-pointer"
                      @click="
                        togglePasswordRetype
                      "
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="
                'rgba(255, 255, 255, 0.15)'
              "
              variant="primary"
              class="mt-1 mr-1"
              @click.prevent="saveChanges"
            >
              <b-spinner
                v-if="loading"
                small
                type="grow"
              />
              <span v-if="!loading">
                Save changes</span>
              <span v-if="loading">
                Saving changes</span>
            </b-button>
            <b-button
              v-ripple.400="
                'rgba(186, 191, 199, 0.15)'
              "
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              Reset
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
// eslint-disable-next-line object-curly-newline
import {
  required,
  email,
  integer,
  length,
  min,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,

      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',

      required,
      email,
      integer,
      length,
      min,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld ===
        'password' ?
        'EyeIcon' :
        'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew ===
        'password' ?
        'EyeIcon' :
        'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype ===
        'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      // eslint-disable-next-line operator-linebreak
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === 'password' ?
          'text' :
          'password'
    },
    togglePasswordNew() {
      // eslint-disable-next-line operator-linebreak
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === 'password' ?
          'text' :
          'password'
    },
    togglePasswordRetype() {
      // eslint-disable-next-line operator-linebreak
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype ===
        'password' ?
          'text' :
          'password'
    },
    showToast(
      title,
      text,
      icon,
      variant,
      timeout = 10000,
    ) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    saveChanges() {
      this.$refs.updatePassword
        .validate()
        .then(async success => {
          if (success) {
            this.loading = true
            await this.$store
              .dispatch(
                'auth/updateUserPassword',
                {
                  old_password:
                    this.passwordValueOld,
                  new_password:
                    this.newPasswordValue,
                },
              )
              .then(() => {
                this.showToast(
                  'Success',
                  'Password successfully updated',
                  'Check',
                  'success',
                  // eslint-disable-next-line comma-dangle
                  15000,
                )
                this.loading = false
              })
              .catch(err => {
                this.loading = false
                if (err.response) {
                  if ('data' in err.response) {
                    if (
                      'data' in err.response.data
                    ) {
                      if (
                        Array.isArray(
                          err.response.data.data,
                        )
                      ) {
                        err.response.data.data.forEach(
                          param => {
                            this.showToast(
                              `Error ${err.response.status}`,
                              param.message,
                              'X',
                              'danger',
                              // eslint-disable-next-line comma-dangle
                              15000,
                            )
                          },
                        )
                      }
                    } else {
                      this.showToast(
                        `Error ${err.response.status}`,
                        `${err.response.data.message}`,
                        'X',
                        'danger',
                        // eslint-disable-next-line comma-dangle
                        15000,
                      )
                    }
                  } else {
                    this.showToast(
                      `Error ${err.response.status}`,
                      `${err.response.statusText}`,
                      'X',
                      'danger',
                      // eslint-disable-next-line comma-dangle
                      15000,
                    )
                    // eslint-disable-next-line function-paren-newline
                  }
                } else {
                  this.showToast(
                    'Error',
                    `${err.message}`,
                    'X',
                    'danger',
                    // eslint-disable-next-line comma-dangle
                    15000,
                  )
                  // eslint-disable-next-line function-paren-newline
                }
              })
          } else {
            this.loading = false
            this.showToast(
              'There seems to be a problem in your submission',
              undefined,
              'X',
              'danger',
              // eslint-disable-next-line comma-dangle
              15000,
            )
          }
        })
    },
  },
}
</script>
