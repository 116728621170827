<template>
  <b-card title="Work Experience">
    <b-card-text>Make sure to indicate the details
      correctly.</b-card-text>
    <validation-observer ref="updateExperience">
      <b-form>
        <b-card-body
          class="
            invoice-padding
            form-item-section
          "
        >
          <div
            ref="form"
            class="repeater-form"
            :style="{ height: trHeight }"
          >
            <b-row
              v-for="(
                item, index
              ) in experience.list"
              :key="index"
              ref="row"
              class="pb-2"
            >
              <b-col cols="12">
                <div class="d-none d-lg-flex">
                  <b-row class="flex-grow-1 px-1">
                    <!-- Single Item Form Headers -->
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      Company
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      Designation
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      Start Date
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      End Date
                    </b-col>
                  </b-row>
                  <div
                    class="form-item-action-col"
                  />
                </div>
                <div
                  class="d-flex border rounded"
                >
                  <b-row class="flex-grow-1 p-2">
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <label
                        class="d-inline d-lg-none"
                      >Company</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Company"
                        rules="required"
                      >
                        <b-form-input
                          :id="`company-${index}`"
                          v-model="item.company"
                          placeholder="Company"
                          class="mb-1"
                          :state="
                            errors.length > 0
                              ? false
                              : null
                          "
                        />
                        <small
                          class="text-danger mb-2"
                        >{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <label
                        class="d-inline d-lg-none"
                      >Designation</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Designation"
                        rules="required"
                      >
                        <b-form-input
                          :id="`designation-${index}`"
                          v-model="
                            item.designation
                          "
                          placeholder="Designation"
                          class="mb-1"
                          :state="
                            errors.length > 0
                              ? false
                              : null
                          "
                        />
                        <small
                          class="text-danger mb-2"
                        >{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <label
                        class="d-inline d-lg-none"
                      >Start Date</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Start Date"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="
                            item.start_date
                          "
                          class="form-control"
                          placeholder="Start date"
                          :state="
                            errors.length > 0
                              ? false
                              : null
                          "
                          :config="{
                            altInput: true,
                            altFormat: 'F j, Y',
                            dateFormat: 'Y-m-d',
                          }"
                        />
                        <small
                          class="text-danger mb-2"
                        >{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <label
                        class="d-inline d-lg-none"
                      >End Date</label>
                      <validation-provider
                        #default="{ errors }"
                        name="End Date"
                        :rules="`required`"
                      >
                        <flat-pickr
                          v-model="item.end_date"
                          class="form-control"
                          placeholder="End date"
                          :state="
                            errors.length > 0
                              ? false
                              : null
                          "
                          :config="{
                            altInput: true,
                            altFormat: 'F j, Y',
                            dateFormat: 'Y-m-d',
                          }"
                          :disabled="
                            !item.start_date
                          "
                        />
                        <small
                          class="text-danger mb-2"
                        >{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      lg="12"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Description"
                        :rules="`required`"
                      >
                        <label
                          class="
                            d-inline d-lg-none
                          "
                        >Description</label>
                        <b-form-textarea
                          v-model="
                            item.description
                          "
                          rows="5"
                          class="mb-2 mb-lg-0"
                          placeholder="Describe this work experience i.e Duties, Salary etc"
                        />
                        <small
                          class="text-danger mb-2"
                        >{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <div
                    class="
                      d-flex
                      flex-column
                      justify-content-between
                      border-left
                      py-50
                      px-25
                    "
                  >
                    <feather-icon
                      size="16"
                      icon="XIcon"
                      class="cursor-pointer"
                      @click="removeItem(index)"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-button
            v-ripple.400="
              'rgba(255, 255, 255, 0.15)'
            "
            size="sm"
            variant="primary"
            :disabled="loading || loadingFirst"
            @click="addNewItemInItemForm"
          >
            <b-spinner
              v-if="loading || loadingFirst"
              small
              type="grow"
            />
            Add Item
          </b-button>
        </b-card-body>

        <b-button
          v-if="experience.list.length > 0"
          v-ripple.400="
            'rgba(255, 255, 255, 0.15)'
          "
          variant="primary"
          class="mt-1 mr-1"
          :disabled="loading || loadingFirst"
          @click.prevent="saveChanges"
        >
          <b-spinner
            v-if="loading || loadingFirst"
            small
            type="grow"
          />
          <span v-if="!loading">
            Save changes</span>
          <span v-if="loading">
            Saving changes</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
/* eslint-disable space-before-function-paren */
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import {
  BButton,
  BForm,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BSpinner,
  BFormTextarea,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
// eslint-disable-next-line object-curly-newline
import {
  required,
  email,
  integer,
  length,
  min,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BSpinner,
    BFormTextarea,
    flatPickr,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadingFirst: true,
      loading: false,
      hasError: false,

      // validation
      required,
      email,
      integer,
      length,
      min,
    }
  },
  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'experience.available': function () {
      this.initTrHeight()
    },
    // eslint-disable-next-line func-names
    'experience.list': function () {
      this.initTrHeight()
    },
    // eslint-disable-next-line func-names
    '$refs.form.scrollHeight': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    this.loadingFirst = true
    window.addEventListener(
      'resize',
      this.initTrHeight,
    )
    setTimeout(() => {
      this.$store
        .dispatch('auth/getUserExperience')
        .then(() => {
          this.$store.state.auth.userExperience.forEach(
            row => {
              this.experience.list.push({
                id: row.id,
                company: row.company,
                designation: row.designation,
                start_date: row.start_date,
                end_date: row.end_date,
                description: row.description,
                fromDB: true,
                canBeDeleted: false,
              })
              this.initTrHeight()
            },
          )
        })
      setTimeout(() => {
        this.loadingFirst = false
      }, 1500)
    }, 1000)
  },
  destroyed() {
    window.removeEventListener(
      'resize',
      this.initTrHeight,
    )
  },
  methods: {
    showToast(
      title,
      text,
      icon,
      variant,
      timeout = 10000,
    ) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.$refs.updateExperience
        .validate()
        .then(async success => {
          if (success) {
            this.hasError = false
            this.$refs.form.style.overflow =
              'hidden'
            this.experience.list.push(
              // eslint-disable-next-line comma-dangle
              JSON.parse(
                JSON.stringify(
                  this.itemFormBlankItem,
                ),
              ),
            )

            this.$nextTick(() => {
              // this.trAddHeight(this.$refs.row[0].offsetHeight)
              this.initTrHeight()
              setTimeout(() => {
                this.$refs.form.style.overflow =
                  null
              }, 350)
            })
          } else {
            if (!this.hasError) {
              this.$nextTick(() => {
                // this.trAddHeight(this.$refs.row[0].offsetHeight / 5)
                this.initTrHeight()
                setTimeout(() => {
                  this.$refs.form.style.overflow =
                    null
                }, 350)
              })
            }
            this.hasError = true
            this.showToast(
              'Opps!',
              'Please correct errors before adding a new row',
              'X',
              'danger',
              // eslint-disable-next-line comma-dangle
              8000,
            )
          }
        })
    },
    removeItem(index) {
      // check and remove items that require the removed index
      if (!this.experience.list[index].fromDB) {
        this.experience.list.splice(index, 1)
        this.initTrHeight()
      } else {
        this.showToast(
          'Invalid Action',
          'You are not allowed to delete this row',
          'X',
          'warning',
          // eslint-disable-next-line comma-dangle
          10000,
        )
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(
          this.$refs.form ?
            this.$refs.form.scrollHeight :
            0,
        )
      })
    },
    saveChanges() {
      this.$refs.updateExperience
        .validate()
        .then(async success => {
          if (success) {
            this.loading = true
            const experience = []
            this.experience.list.forEach(
              value => {
                if (value.company != null) {
                  experience.push({
                    id: value.id,
                    company: value.company,
                    designation:
                      value.designation,
                    start_date: value.start_date,
                    end_date: value.end_date,
                    description:
                      value.description,
                  })
                }
              },
            )
            await this.$store
              .dispatch(
                'auth/updateUserExperience',
                { experience },
              )
              .then(res => {
                const dbExperience = res.data.data
                dbExperience.forEach((_, i) => {
                  dbExperience[i].fromDB = true
                  dbExperience[
                    i
                  ].canBeDeleted = false
                })
                this.experience.list =
                  dbExperience
                this.showToast(
                  'Success',
                  'Experience data successfully processed.',
                  'Check',
                  'success',
                  // eslint-disable-next-line comma-dangle
                  15000,
                )
                this.loading = false
              })
              .catch(err => {
                this.loading = false
                if (err.response) {
                  if ('data' in err.response) {
                    if (
                      'data' in err.response.data
                    ) {
                      if (
                        Array.isArray(
                          err.response.data.data,
                        )
                      ) {
                        err.response.data.data.forEach(
                          param => {
                            this.showToast(
                              `Error ${err.response.status}`,
                              param.message,
                              'X',
                              'danger',
                              // eslint-disable-next-line comma-dangle
                              15000,
                            )
                          },
                        )
                      }
                    } else {
                      this.showToast(
                        `Error ${err.response.status}`,
                        `${err.response.data.message}`,
                        'X',
                        'danger',
                        // eslint-disable-next-line comma-dangle
                        15000,
                      )
                    }
                  } else {
                    this.showToast(
                      `Error ${err.response.status}`,
                      `${err.response.statusText}`,
                      'X',
                      'danger',
                      // eslint-disable-next-line comma-dangle
                      15000,
                    )
                    // eslint-disable-next-line function-paren-newline
                  }
                } else {
                  this.showToast(
                    'Error',
                    `${err.message}`,
                    'X',
                    'danger',
                    // eslint-disable-next-line comma-dangle
                    15000,
                  )
                  // eslint-disable-next-line function-paren-newline
                }
              })
          } else {
            this.loading = false
            this.showToast(
              'There seems to be a problem in your submission',
              undefined,
              'X',
              'danger',
              // eslint-disable-next-line comma-dangle
              15000,
            )
          }
        })
    },
  },
  setup() {
    const experience = ref({
      list: [],
      available: [],
    })

    const itemFormBlankItem = {
      id: null,
      company: null,
      designation: null,
      start_date: null,
      end_date: null,
      description: null,
      fromDB: false,
      canBeDeleted: true,
    }

    return {
      experience,
      itemFormBlankItem,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
