<template>
  <div>
    <b-tabs
      v-model="tabIndex"
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">General</span>
        </template>

        <profile-setting-personal />
      </b-tab>
      <!--/ personal tab -->

      <!-- info -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">More Information</span>
        </template>

        <profile-setting-information />
      </b-tab>

      <b-tab lazy>
        <template #title>
          <feather-icon
            icon="BookOpenIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Education</span>
        </template>

        <profile-setting-education />
      </b-tab>

      <b-tab lazy>
        <template #title>
          <feather-icon
            icon="BriefcaseIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Experience</span>
        </template>

        <profile-setting-experience />
      </b-tab>

      <b-tab lazy>
        <template #title>
          <feather-icon
            icon="AtSignIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Referees</span>
        </template>

        <profile-setting-referees />
      </b-tab>

      <b-tab lazy>
        <template #title>
          <feather-icon
            icon="FileTextIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Documents</span>
        </template>

        <profile-setting-documents />
      </b-tab>

      <!-- change password tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Change Password</span>
        </template>

        <profile-setting-password />
      </b-tab>
      <!--/ change password tab -->
    </b-tabs>
    <div class="d-flex justify-content-end mb-2">
      <b-button-group class="mt-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="tabIndex--"
        >
          Previous
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="tabIndex++"
        >
          Next
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { BTabs, BTab, BButtonGroup, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import ProfileSettingPersonal from './ProfileSettingPersonal.vue'
import ProfileSettingPassword from './ProfileSettingPassword.vue'
import ProfileSettingInformation from './ProfileSettingInformation.vue'
import ProfileSettingEducation from './ProfileSettingEducation.vue'
import ProfileSettingExperience from './ProfileSettingExperience.vue'
import ProfileSettingReferees from './ProfileSettingReferees.vue'
import ProfileSettingDocuments from './ProfileSettingDocuments.vue'

export default {
  components: {
    BTabs,
    BTab,
    BButtonGroup,
    BButton,

    ProfileSettingPersonal,
    ProfileSettingPassword,
    ProfileSettingInformation,
    ProfileSettingEducation,
    ProfileSettingExperience,
    ProfileSettingReferees,
    ProfileSettingDocuments,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      options: {},
      tabIndex: 1,
    }
  },
  computed: {},
  created() {
    this.$store.dispatch('auth/getUserPersonalInfo')
  },
}
</script>
