var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-link',[_c('b-img',{ref:"previewEl",attrs:{"rounded":"","src":require('@/assets/images/avatars/profile.png'),"height":"80"}})],1)],1),_c('b-media-body',{staticClass:"mt-75 ml-75"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
          'rgba(255, 255, 255, 0.15)'
        ),expression:"\n          'rgba(255, 255, 255, 0.15)'\n        ",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.resendConfirmation,
          _vm.$refs.refInputEl.$el.click()}}},[_vm._v(" Upload ")]),_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif","hidden":true,"plain":""},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.profileFile),callback:function ($$v) {_vm.profileFile=$$v},expression:"profileFile"}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
          'rgba(186, 191, 199, 0.15)'
        ),expression:"\n          'rgba(186, 191, 199, 0.15)'\n        ",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"outline-secondary","size":"sm"}},[_vm._v(" Reset ")]),_c('b-card-text',[_vm._v("Allowed JPG, GIF or PNG. Max size of 2Mb")])],1)],1),_c('validation-observer',{ref:"updateForm"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"given_name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"given_name","name":"given_name","state":errors.length > 0
                    ? false
                    : null,"placeholder":"John"},model:{value:(
                  _vm.optionsLocal.given_name
                ),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "given_name", $$v)},expression:"\n                  optionsLocal.given_name\n                "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Middle Name (Optional)","label-for":"middle_name"}},[_c('validation-provider',{attrs:{"name":"Middle Name","rules":"alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"middle_name","name":"middle_name","state":errors.length > 0
                    ? false
                    : null,"placeholder":"Doe"},model:{value:(
                  _vm.optionsLocal.middle_name
                ),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "middle_name", $$v)},expression:"\n                  optionsLocal.middle_name\n                "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"family_name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"family_name","name":"family_name","state":errors.length > 0
                    ? false
                    : null,"placeholder":"Smith"},model:{value:(
                  _vm.optionsLocal.family_name
                ),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "family_name", $$v)},expression:"\n                  optionsLocal.family_name\n                "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Username","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required|alpha-dash|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0
                    ? false
                    : null,"placeholder":"johndoe"},model:{value:(
                  _vm.optionsLocal.preferred_username
                ),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "preferred_username", $$v)},expression:"\n                  optionsLocal.preferred_username\n                "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Email Address","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"email","state":errors.length > 0
                    ? false
                    : null,"placeholder":"john@example.com"},model:{value:(_vm.optionsLocal.email),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "email", $$v)},expression:"optionsLocal.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"phone_number"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required|integer|length:10|regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone_number","name":"phone_number","state":errors.length > 0
                    ? false
                    : null,"placeholder":"0xxxxxxxxx"},model:{value:(
                  _vm.optionsLocal.phone_number
                ),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "phone_number", $$v)},expression:"\n                  optionsLocal.phone_number\n                "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.optionsLocal.email_verified)?_c('b-col',{staticClass:"mt-75",attrs:{"cols":"12"}},[_c('b-alert',{staticClass:"mb-50",attrs:{"show":"","variant":"warning"}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Your email is not confirmed. Please check your inbox. ")]),_c('div',{staticClass:"alert-body"},[_c('b-link',{staticClass:"alert-link",on:{"click":function($event){$event.stopPropagation();return _vm.resendConfirmation.apply(null, arguments)}}},[_vm._v(" Resend confirmation ")])],1)])],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
              'rgba(255, 255, 255, 0.15)'
            ),expression:"\n              'rgba(255, 255, 255, 0.15)'\n            ",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.saveChanges.apply(null, arguments)}}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),(!_vm.loading)?_c('span',[_vm._v(" Save changes")]):_vm._e(),(_vm.loading)?_c('span',[_vm._v(" Saving changes")]):_vm._e()],1),(!_vm.dataMatches)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
              'rgba(186, 191, 199, 0.15)'
            ),expression:"\n              'rgba(186, 191, 199, 0.15)'\n            ",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_vm._v(" Reset ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }